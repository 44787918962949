@import './animation.css';
@import './override.css';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    scrollbar-width: thin;
    scrollbar-color: var(--white) var(--white);
}

*::-webkit-scrollbar {
    width: 2px;
}

*::-webkit-scrollbar-track {
    background: var(--grey);
    border-radius: 1px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--grey);
    border-radius: 1rem;
    border: 1px solid var(--purple);
}

*,
::before,
::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: var(--white);
}

:root {
    --ff: "Inter", sans-serif;
    --fs: 16px;

    --white: #ffffff;
    --black: #353535;
    --black-light: #57575633;
    --black-skeleton: #2b2b2b33;
    --grey: #e5e5e5;
    --grey-darker: #bdb9b9;
    --maroon: #9f9f9f;
    --grey-light: #e5e5e5;
    --purple: #a17efa;
    --purple-darker: #855cec;
    --purple-faint: #dccfff;
    --green: #00d384;
    --red: #ff2257;
    --yellow: #FFC400;
    --blue: #1ec3ff;
    --orange: #ff6600;

    --border-radius: 0.7rem;
    --btn-radius: 0.4rem;
    --base-transition: 0.5s all;
    --theme-transition: 0.2s all;

    --swiper-theme-color: #855cec !important;
}

html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variation-settings: normal;
}

body {
    margin: 0;
    line-height: inherit;
    font-size: var(--fs) !important;
    font-family: var(--ff) !important;
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
    margin: 0;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.2rem;
}

p,
li {
    font-size: 1rem;
    margin: 0;
}

ul {
    padding-inline: 0;
    margin: 0;
}

li {
    list-style-type: none;
}

@media (min-width: 768px) {
    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 1.8rem;
    }

    h4 {
        font-size: 1.5rem;
    }
}

a {
    color: inherit;
    text-decoration: inherit;
}

a:hover {
    text-decoration: none;
    color: var(--white);
}

b,
strong {
    font-weight: bolder;
}

code,
kbd,
samp,
pre {
    font-family: var(--ff);
    font-size: var(--fs);
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
}

input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    background-color: var(--white);
}

select {
    text-transform: none;
}

:-moz-focusring {
    outline: auto;
}

:-moz-ui-invalid {
    box-shadow: none;
}

progress {
    vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    -webkit-appearance: textfield;
    appearance: textfield;
    outline-offset: -2px;
}

::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

summary {
    display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0;
}

fieldset {
    margin: 0;
    padding: 0;
}

legend {
    padding: 0;
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

input,
textarea {
    border: 1px solid var(--grey);
    border-radius: 0.5rem;
    padding: 0.4rem 0.7rem;
    cursor: pointer;
    transition: 0.2s all;
    font-size: 1.1rem;
    width: 100%;
}

input:hover,
textarea:hover,
input:focus,
textarea:focus {
    outline: none;
    border: 1px solid var(--purple);
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;
    color: #9ca3af;
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af;
    font-style: italic;
    font-size: 1.1rem;
}

:disabled {
    cursor: default;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
}

img,
video {
    max-width: 100%;
    height: auto;
}

[hidden] {
    display: none;
}

.border-\[40px\] {
    border-width: 40px;
}

.bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}

.underline {
    text-decoration-line: underline;
}