.leaflet-top .leaflet-left {
    display: none !important;
}

.popup-content {
    background-color: red;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    color: #333;
}

.leaflet-right {
    display: none !important;
}


.leaflet-popup-content-wrapper {
    background-color: white !important;
}

.leaflet-popup-content {
    font-size: 13px;
    font-size: 0 !important;
    line-height: 0 !important;
    margin: 0 !important;
    min-height: 0 !important;
    padding: 0.5rem 0.5rem !important;
    max-width: 200px !important;
    width: 100%;
}

@media(min-width:768px) {
    .leaflet-popup-content {
        max-width: 400px !important;
        padding: 0.7rem !important;
    }
}

.leaflet-popup-content p {
    margin: 0 !important;
    line-height: 1 !important;
}

.recharts-legend-wrapper {
    display: none !important;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.swiper-pagination-bullet .swiper-pagination-bullet-active {
    background-color: var(--purple) !important;
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    width: 60px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.swiper-button-prev:after,
.swiper-button-next:after {
    width: 50px !important;

    font-family: none !important;
    font-size: 1rem !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
}

.swiper-button-prev:after {
    content: url('../assets/icons/chev-prev.svg') !important;
}

.swiper-button-next:after {
    content: url('../assets/icons/chev-next.svg') !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

@media(max-width: 768px) {

    .swiper-button-prev:after,
    .swiper-button-next:after {
        display: none !important;
    }
}

.rdp,
.rdp-month {
    margin: 0 !important;
}

.rdp-months {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;

}

.rdp-day_selected {
    background-color: #a17efa !important;
    color: #ffffff !important;
}

.rdp-month .rdp-caption_start .rdp-caption_end {
    width: 100% !important;
}

.leaflet-popup-close-button {
    background-color: red !important;
    position: relative;
}

.leaflet-popup-close-button::after {
    content: 'X' !important;
    color: white !important;
    position: absolute
}

.leaflet-popup-close-button span {
    font-size: 1rem !important;
}

.leaflet-control-zoom-out span,
.leaflet-control-zoom-in span {
    display: none !important;
}