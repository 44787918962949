.fade-in__bottom {
    animation: square-in-top-left;
}

@keyframes square-in-top-left {
    from {
        clip-path: inset(100% 0 0 100%);
    }

    to {
        clip-path: inset(0 0 0 0);
    }
}

[transition-style="in:square:top-left"] {
    animation: 2.5s cubic-bezier(.25, 1, .30, 1) square-in-top-left both;
}

.fade-in {
    animation: fade-in 0.5s ease 0s 1 normal forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-in__left {
    animation: fade-in__left 0.3s ease 0s 1 normal forwards;
}

@keyframes fade-in__left {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-spin__custom {
    animation: spin__custom 1.4s linear infinite;
}

@keyframes spin__custom {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.fade-in__navigation {
    animation: fade-in__navigation 1s ease 0s 1 normal forwards;

}

@keyframes fade-in__navigation {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}